import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import style from './style.module.css';
import ValidSMSModal from '../ValidSMSModal';
import ThanksCouponModal from '../ThanksCouponModal';
import RegInput from './RegInput';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            modalIsOpen: false,
            thanksCouponIsOpen: false
        }
    }
    
    closeThanksCouponModal = () => {
        this.setState({thanksCouponIsOpen: false});
    }

    componentDidMount = async () => {
        this.setState({
            errors: []
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.props.myShopApiStore.setUserParams(name, value);
        this.setState({
            errors: this.state.errors.filter(error => error !== name),
            isButtonDisabled: false
        })
    };
   
   

    handleSubmit = () => {
        const { validateUserParams, shopData } = this.props.myShopApiStore;
        
        const errors = validateUserParams([
            'name', 'phone', 'city', 'streetName', 'streetNumber', 
            ...(['Israel'].includes(shopData?.country.name) ? [] : ['state', 'zipCode']) //additional fields for Stripe
        ]);

        this.setState({
            errors: errors
        });

        if(!errors.length) {
            this.setState({isButtonDisabled: true});
            setTimeout(() => this.setState({isButtonDisabled: false}),4000);
            this.props.myShopApiStore.registerUser();
            this.setState({modalIsOpen: true});
        }
    }

    render() {
        const { t } = this.props;
        const { country } = this.props.myShopApiStore.shopData;
        return (
        <div className={style.container}>
            <ThanksCouponModal modalIsOpen={this.state.thanksCouponIsOpen} closeModal={this.closeThanksCouponModal}/>
            <div className={style.Header}> {t('Hot Welcome')}<br/> {t('Will be happy')} </div>
            <div className={style.FormContainer}>
                <div className={style.Form}>
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                type="text"
                                className = {this.state.errors.includes("name") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.name} 
                                name="name"
                                onChange={this.handleChange} 
                                placeholder={t('full name')}
                            />
                        </div>
                    </div>
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            {/* <input 
                                type="tel"
                                className = {this.state.errors.includes("phone") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.phone} 
                                name="phone"
                                onChange={this.handleChange} 
                                placeholder={t('mobile phone')}
                                style={{textAlign: document.body.dir === 'rtl' ? 'right' : 'left'}}
                            /> */}
                            <RegInput
                                type="tel"
                                className = {this.state.errors.includes("phone") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams?.phone}
                                name='phone'
                                onChange={this.handleChange}
                                placeholder={t('mobile phone')}
                            />

                        </div>
                    </div>
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                type="email"
                                className = {this.state.errors.includes("email") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.email} 
                                name="email"
                                onChange={this.handleChange} 
                                placeholder={t('e-mail')}
                            />
                        </div>
                    </div>
                    { country && !country.default && country.name !== 'Israel' && <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                className={this.state.errors.includes('state') ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.zipCode}
                                name='state'
                                onChange={this.handleChange}
                                placeholder={t('state')}
                            />
                        </div>
                    </div> }
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                className = {this.state.errors.includes("city") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.city} 
                                name="city"
                                onChange={this.handleChange} 
                                placeholder={t('city / town')}
                            />
                        </div>
                    </div>
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                className = {this.state.errors.includes("streetName") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.streetName} 
                                name="streetName"
                                onChange={this.handleChange} 
                                placeholder={t('street')}
                            />
                        </div>
                    </div>
                    { country && !country.default && country.name !== 'Israel' && <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                className={this.state.errors.includes('zipCode') ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.zipCode}
                                name='zipCode'
                                onChange={this.handleChange}
                                placeholder={t('zipCode')}
                            />
                        </div>
                    </div> }
                    <div className={style.FormLine}>
                        <div className={style.FormLineTwo}>
                            <input 
                                className = {this.state.errors.includes("streetNumber") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.streetNumber} 
                                name="streetNumber"
                                onChange={this.handleChange} 
                                placeholder={t('no. home')}
                            />
                        </div>
                        <div className={style.FormLineTwo}>
                            <input 
                                className = {this.state.errors.includes("entranceCode") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.entranceCode} 
                                name="entranceCode"
                                onChange={this.handleChange} 
                                placeholder={t('_entry code')}
                            />
                        </div>
                    </div>
                    <div className={style.FormLine}>
                        <div className={style.FormLineTwo}>
                            <input 
                                className = {this.state.errors.includes("apartmentNumber") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.apartmentNumber} 
                                name="apartmentNumber"
                                onChange={this.handleChange} 
                                placeholder={t('flat number')}
                            />
                        </div>
                        <div className={style.FormLineTwo}>
                            <input 
                                className = {this.state.errors.includes("floorNumber") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.floorNumber} 
                                name="floorNumber"
                                onChange={this.handleChange} 
                                placeholder={t('floor')}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.RegisterButton} style={ this.state.isButtonDisabled?{backgroundColor: 'rgb(0, 0, 0, 0.2)'}:{}} onClick={this.state.isButtonDisabled ? null : this.handleSubmit}>
                {t('registration')}
            </div>
            {//this.state.modalIsOpen && 
                <ValidSMSModal closeModal={() => this.setState({modalIsOpen: false})} modalIsOpen={this.state.modalIsOpen} action='Registration'/>}
        </div>
        )
    }
}

export default withTranslation()(Registration);